import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {zoomLevel} from "zoom-level";

export default function FullPattiChart() {
    const [draws, setDraws] = useState([]);
    const [drawHeading, setDrawHeading] = useState([]);
    const {drawName, jodi, pattern} = useParams();
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const groupDrawsByWeek = (drawsData) => {
        const groupedDraws = {};
        
        // Get maximum allowed days based on draw name
        const getMaxDays = () => {
            const fiveDaysDraws = ["rajdhani-night", "tara-mumbai-night", "kalyan-night", "main-bazar"];
            const sixDaysDraws = [
                "good-morning", "bombay-exp", "golden", "kalyan", "goldstar", "golden-night",
                "time-bazar", "tara-mumbai-day", "milan-day", "rajdhani-day", "milan-night",
                "night-time-bazar", "madhur-night", "mahaveer", "mahakal"
            ];
            
            if (fiveDaysDraws.includes(drawName)) return 5;
            if (sixDaysDraws.includes(drawName)) return 6;
            return 7;
        };

        const maxDays = getMaxDays();

        // Group draws by week
        drawsData.forEach((draw) => {
            const weekStart = new Date(draw.sortDate);
            weekStart.setDate(weekStart.getDate() - (weekStart.getDay() - 1 + 7) % 7);
            
            const weekStartString = weekStart.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
            });

            if (!groupedDraws[weekStartString]) {
                groupedDraws[weekStartString] = [];
            }

            // Only add draw if we haven't reached max days for this draw type
            if (groupedDraws[weekStartString].length < maxDays) {
                groupedDraws[weekStartString].push(draw);
            }
        });

        // Process each week
        Object.keys(groupedDraws).forEach((key) => {
            const newDraws = [];
            let currentDay = 0;

            groupedDraws[key].forEach((draw) => {
                const drawDay = customDay(draw.sortDate);
                
                // Only add draws for valid operating days
                if (drawDay < maxDays) {
                    // Fill gaps with empty slots if needed
                    while (currentDay < drawDay) {
                        newDraws.push({
                            isEmpty: true,
                            sortDate: new Date()
                        });
                        currentDay++;
                    }
                    newDraws.push(draw);
                    currentDay = drawDay + 1;
                }
            });

            groupedDraws[key] = newDraws;
        });

        return groupedDraws;
    };

    const customDay = (date) => {
        const dayIndex = date.getDay(); // This returns 0 for Sunday, 1 for Monday, ..., 6 for Saturday
        return dayIndex === 0 ? 6 : dayIndex - 1; // Adjust Sunday to be at index 6 instead of 0
    };

    const searchMumbaiPattern = async () => {
        try {
            const patternType = pattern === "d" ? "delhiPattern" : "mumbaiPattern";
            const response = await fetch(`https://dp-anna-api.web.app/api/searchPatternDraw?drawName=${drawName}&pattern=${patternType}`);
            const result = await response.json();

            if (result.success) {
                const { drawDetails, draws } = result.data;
                
                if (drawDetails) {
                    setStartTime(drawDetails.startTime);
                    setEndTime(drawDetails.closingTime);
                    setDrawHeading(drawDetails.drawName);
                }

                // Transform the dates to Date objects for grouping
                const drawsWithDates = draws.map(draw => ({
                    ...draw,
                    sortDate: new Date(draw.date.split('/').reverse().join('-')) // Convert DD/MM/YYYY to YYYY-MM-DD
                }));

                setDraws(drawsWithDates);
            } else {
                console.error("API call failed:", result.error);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Add a new useEffect to log the times for debugging
    useEffect(() => {
        console.log('Times updated:', { startTime, endTime, drawHeading });
    }, [startTime, endTime, drawHeading]);

    useEffect(() => {
        searchMumbaiPattern();
    }, [drawName, pattern]);



    const groupedDraws = groupDrawsByWeek(draws);

    function getEndOfWeek(startDateString) {
        // Convert the input string to a Date object
        const [day, month, year] = startDateString.split('/').map(Number);
        const start = new Date(year, month - 1, day);

        // Check if the conversion is successful
        if (isNaN(start.getTime())) {
            return "Invalid date"; // Return an error message for invalid dates
        }

        const end = new Date(start);

        // Calculate the end of the week (Sunday)
        end.setDate(start.getDate() + (6 - start.getDay()));

        // Format the end date as DD/MM/YYYY
        const endString = `${end.getDate()}/${end.getMonth() + 1}/${end.getFullYear()}`;

        return endString;
    }


    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [groupedDraws]);

    zoomLevel()

    let fiveDaysReturn = {
        mainDiv: "grid grid-cols-6",
        innerDiv: "col-span-6 grid grid-cols-6",
        daysArray: ["Date", "Mon", "Tue", "Wed", "Thu", "Fri"]
    };

    let sixDaysReturn = {
        mainDiv: "grid grid-cols-7",
        innerDiv: "col-span-7 grid grid-cols-7",
        daysArray: ["Date", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    };

    let sevenDaysReturn = {
        mainDiv: "grid grid-cols-8",
        innerDiv: "col-span-8 grid grid-cols-8",
        daysArray: ["Date", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    };

    const numberOfWeeksToShowAndDaysToShow = () => {
        const sixDaysReturnDraws = [
            "good-morning", "bombay-exp", "golden", "kalyan", "goldstar", "golden-night",
            "time-bazar", "tara-mumbai-day", "milan-day", "rajdhani-day", "milan-night",
            "night-time-bazar", "madhur-night", "mahaveer", "mahakal"
        ];

        const sevenDaysReturnDraws = [
            "sridevi", "madhur-morning", "madhur-day", "sridevi-night", "goa-golden"
        ];

        const fiveDaysReturnDraws = [
            "rajdhani-night", "tara-mumbai-night", "kalyan-night", "main-bazar"
        ];

        if (sixDaysReturnDraws.includes(drawName)) {
            return {...sixDaysReturn, daysArray: sixDaysReturn.daysArray.slice(0, 7)};
        } else if (sevenDaysReturnDraws.includes(drawName)) {
            return sevenDaysReturn;
        } else if (fiveDaysReturnDraws.includes(drawName)) {
            return {...fiveDaysReturn, daysArray: fiveDaysReturn.daysArray.slice(0, 6)};
        } else {
            return null; // Handle the case where drawName does not match any case
        }
    };


    const hideColumnsForDraw = (drawName, drawIndex) => {
        const hideCasesByIndex = {
            7: ["good-morning", "bombay-exp", "golden", "kalyan", "goldstar", "golden-night",
                "time-bazar", "tara-mumbai-day", "milan-day", "rajdhani-day", "goa-golden",
                "night-time-bazar", "mahaveer", "mahakal"],
            8: ["sridevi", "madhur-morning", "madhur-day", "sridevi-night"],
            6: ["tara-mumbai-night", "milan-night", "madhur-night"],
            5: ["rajdhani-night", "main-bazar", "kalyan-night"]
        };

        return hideCasesByIndex[drawIndex] && hideCasesByIndex[drawIndex].includes(drawName)
            ? 'hidden'
            : '';
    };

    return (
        <div className={`mx-auto max-w-[100vw] min-w-[350px] lg:min-w-[1300px] bg-gradient-to-b from-gray-900 to-gray-800`}>
            {/* Sticky header container */}
            <div className="sticky top-0 z-50">
                {/* Enhanced top bar */}
                <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 border-b border-gray-700">
                    <div className="max-w-7xl mx-auto py-3 px-4">
                        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                            {/* Time Section */}
                            <div className="flex items-center gap-8 text-xs sm:text-sm md:text-base ">
                                <div className="flex items-center gap-2">
                                    <span className="text-amber-400 font-medium uppercase tracking-wider">Open</span>
                                    <span className="text-emerald-400 font-bold tracking-wide bg-gray-800/50 px-2 py-1 rounded">
                                        {startTime || 'Loading...'}
                                    </span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <span className="text-amber-400 font-medium uppercase tracking-wider">Close</span>
                                    <span className="text-emerald-400 font-bold tracking-wide bg-gray-800/50 px-2 py-1 rounded">
                                        {endTime || 'Loading...'}
                                    </span>
                                </div>
                            </div>

                            {/* Draw Name */}
                            <div className="flex-1 text-center">
                                <h1 className="text-amber-300 text-lg sm:text-xl md:text-2xl font-black uppercase tracking-[0.2em] 
                                             bg-clip-text text-transparent bg-gradient-to-r from-amber-200 via-amber-300 to-amber-200">
                                    {drawHeading}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Sticky days bar - now part of the sticky header container */}
                <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 shadow-lg border-b border-gray-700">
                    <div className={`${pattern === "d" ? sevenDaysReturn.innerDiv : numberOfWeeksToShowAndDaysToShow().innerDiv} py-3`}>
                        {(pattern === "d" ? ["Date", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"] : numberOfWeeksToShowAndDaysToShow().daysArray).map((day, index) => (
                            <div
                                key={day}
                                className="font-bold text-xs sm:text-sm md:text-base lg:text-lg text-amber-300 flex justify-center items-center"
                            >
                                {day}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Main content */}
            <div className={`${pattern === "d" ? sevenDaysReturn.mainDiv : numberOfWeeksToShowAndDaysToShow().mainDiv} mt-2`}>
                {Object.keys(groupedDraws).map((weekStartString, weekIndex) => (
                    <div key={weekStartString} className={`border-t border-gray-700 ${pattern === "d" ? sevenDaysReturn.innerDiv : numberOfWeeksToShowAndDaysToShow().innerDiv} bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900`}>
                        <div className="col-span-1 text-[9px] sm:pl-2 text-emerald-400 font-bold md:text-base lg:text-2xl py-2 sm:py-6 flex flex-col items-center">
                            <div className="text-[8px] sm:text-base md:text-lg lg:text-2xl">{weekStartString}</div>
                            <div>
                                <svg className="w-4" xmlns="http://www.w3.org/2000/svg" fill="#10b981" viewBox="0 0 256 256">
                                    <path d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z"></path>
                                </svg>
                            </div>
                            <div className="text-[8px] sm:text-base md:text-lg lg:text-2xl">{getEndOfWeek(weekStartString)}</div>
                        </div>
                        {groupedDraws[weekStartString].map((draw, drawIndex) => (
                            <div
                                key={draw.sortDate.toLocaleDateString()}
                                className={`flex border-l border-gray-700 items-center sm:py-5 justify-center hover:bg-gray-800 transition-colors duration-200`}
                            >
                                <div className={`flex justify-evenly ${pattern === "d" ? "" : hideColumnsForDraw(drawIndex, drawIndex)}`}>
                                    {(jodi === "false" && pattern === "m") && (
                                        <div className="flex flex-col text-[11px] font-extrabold sm:text-3xl text-amber-300">
                                            {draw.openRoundPatti
                                                ? draw.openRoundPatti.split('').map((digit, index) => (
                                                    <span key={index} className="">
                                            {digit}
                                        </span>
                                                ))
                                                : Array.from({length: 3}, (_, index) => (
                                                    <span key={index} className="">*</span>
                                                ))}
                                        </div>
                                    )}
                                    {(pattern === "m") && (
                                        <div className={`md:mx-2 p-1 text-sm font-black lg:text-7xl md:text-3xl flex flex-col justify-center lg:p-4 ${getMiddlePattiColorClass(getMiddlePatti(draw.openRoundPatti, draw.closeRoundPatti))}`}>
                                            {getMiddlePatti(draw.openRoundPatti, draw.closeRoundPatti)}
                                        </div>
                                    )}
                                    {(pattern === "d") && (
                                        <div className="lg:mx-4 text-sm lg:text-7xl md:text-3xl font-black text-amber-300 flex flex-col justify-center lg:p-4 transition-all duration-200 hover:scale-110">
                                            {draw.number}
                                        </div>
                                    )}
                                    {(jodi === "false" && pattern === "m") && (
                                        <div className="flex flex-col text-[11px] font-extrabold sm:text-3xl text-amber-200">
                                            {draw.closeRoundPatti
                                                ? draw.closeRoundPatti.split('').map((digit, index) => (
                                                    <span key={index} className="mr-1">
                                            {digit}
                                        </span>
                                                ))
                                                : Array.from({length: 3}, (_, index) => (
                                                    <span key={index} className="mr-1">*</span>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

        </div>
    );
}

export const getMiddlePattiColorClass = (middlePatti) => {
    const numbersToCheck = ["16", "27", "38", "49", "50", "61", "72", "83", "94", "05", "11", "22", "33", "44", "55", "66", "77", "88", "99", "00"];
    const containsMatchingNumber = numbersToCheck.some(number => middlePatti.includes(number));
    return containsMatchingNumber ? "text-red-500 font-black animate-pulse" : "text-amber-200 font-black";
};

export const getMiddlePatti = (open, close) => {
    const openNumber = parseInt(open, 10);
    const closeNumber = parseInt(close, 10);

    const openDigits = isNaN(openNumber) ? [] : openNumber.toString().split('').map(Number);
    const closeDigits = isNaN(closeNumber) ? [] : closeNumber.toString().split('').map(Number);

    const openSum = openDigits.reduce((a, b) => a + b, 0);
    const closeSum = closeDigits.reduce((a, b) => a + b, 0);

    const openLastDigit = openDigits.length > 0 ? openSum.toString().slice(-1) : '*';
    const closeLastDigit = closeDigits.length > 0 ? closeSum.toString().slice(-1) : '*';

    if (openDigits.length > 0 && closeDigits.length > 0) {
        return `${openLastDigit}${closeLastDigit}`;
    } else if (openDigits.length > 0) {
        return `${openLastDigit}*`;
    } else if (closeDigits.length > 0) {
        return `*${closeLastDigit}`;
    } else {
        return '**';
    }
};
